<template>
    <div class="comp-comment" ref="comment_page" @scroll="onScroll">
        <div class="comment-box" ref="comment_box" :style="{ 'column-count': count }">
            <div class="item-base" v-for="(item, idx) in list" :key="idx">
                <div class="item-box">
                    <div class="avatar">
                        <img v-if="item.avatarUrl" class="img" :src="item.avatarUrl" alt="" />
                        <Icon v-else type="md-person" size="20" />
                    </div>
                    <div class="info-box">
                        <div class="userinfo">
                            <div class="username">{{ item.userName }}</div>
                            <div class="date">{{ $core.formatDate(new Date(item.gmtCreate), "MM月dd日评") }}</div>
                        </div>
                        <div class="content">{{ item.content }}</div>
                        <div class="photo-box">
                            <div class="item" v-for="(src, idx) in item.photos" :key="idx" @click="previewImage(src, item.photos)" :style="{ 'background-image': 'url(' + src + ')' }"></div>
                        </div>
                    </div>
                    <div class="control">
                        <Checkbox :value="item.status == 2" @on-change="onChangeStatus(item)">公开</Checkbox>
                    </div>
                </div>
            </div>
        </div>
        <div class="empty" v-if="!list">加载中...</div>
        <div class="empty" v-else-if="list.length === 0">暂无评价</div>

        <Preview ref="preview"></Preview>
    </div>
</template>

<script>
import Preview from "../../comment/components/Preview.vue"
import RequestPage from "../../jointly/utils/page"
import Request from "../../jointly/utils/request"

export default {
    components: { Preview },

    data() {
        return {
            list: [],
            count: 1,
        }
    },

    props: {
        forumId: String,
        forumType: String,
    },

    watch: {
        forumId: {
            handler(v) {
                this.reqPage?.setData({ infoId: v })
            },
        },
    },

    created() {
        this.reqPage = new RequestPage("/gateway/syinfopublish/api/app/infoComments/listComments", {
            load: this.forumId,
            data: {
                infoId: this.forumId,
                infoType: this.forumType,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                excludeOneself: 2,
            },
            onChange: res => {
                this.list = this.list.concat(
                    res?.map(v => {
                        v.photos = v.photo ? v.photo.split(",") : null
                        return v
                    }) || []
                )

                setTimeout(() => {
                    this.onResize()
                }, 100)
            },
        })
    },

    mounted() {
        // 监听浏览器宽度变化事件
        window.addEventListener("resize", this.onResize.bind(this))
        // 初始
        this.onResize()
    },

    methods: {
        onResize() {
            if (this.$refs.comment_page?.clientHeight > this.$refs.comment_box?.clientHeight) {
                this.reqPage.load()
            }
            let width = this.$refs.comment_box?.clientWidth
            // 根据宽度显示列数
            this.count = width > 820 ? 2 : 1
        },

        previewImage(url, urls) {
            this.$refs.preview.display(url, urls)
        },

        onChangeStatus(item) {
            Request.post("/gateway/syinfopublish/api/app/infoComments/updateDisplayStatus", {
                id: item.id,
            }).then(() => {
                this.$set(item, "status", item.status == 2 ? 1 : 2)
            })
        },

        onScroll(evt) {
            const t = evt.target
            if (t.scrollHeight - 10 <= t.scrollTop + t.clientHeight) {
                if (!this.bottoming) {
                    this.reqPage.load()
                }
                this.bottoming = true
            } else {
                this.bottoming = false
            }
        },
    },
}
</script>

<style lang="less">
.comp-comment {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    overflow: auto;

    .comment-box {
        width: 100%;

        .item-base {
            padding: 5px;
        }

        .item-box {
            min-width: 400px;
            max-width: 800px;
            padding: 10px;
            display: flex;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(150, 150, 150, 0.1);

            .avatar {
                width: 40px;
                height: 40px;
                flex-shrink: 0;
                margin-right: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #e3e3e3;
                border-radius: 50%;
                overflow: hidden;

                .img {
                    width: 40px;
                    height: 40px;
                }
            }

            .info-box {
                flex-grow: 1;
                overflow: hidden;

                .userinfo {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .username {
                        color: #777;
                        font-size: 13px;
                    }

                    .date {
                        color: #999;
                        font-size: 12px;
                    }
                }

                .content {
                    word-wrap: break-word;
                }

                .photo-box {
                    display: flex;
                    flex-wrap: wrap;

                    .item {
                        cursor: pointer;
                        width: 50px;
                        height: 50px;
                        border-radius: 4px;
                        overflow: hidden;
                        border: 1px solid #e3e3e3;
                        margin: 5px 5px 5px 0;
                        background-position: center;
                        background-size: cover;
                    }
                }
            }

            .control {
                margin-left: 20px;
                height: 40px;
                display: flex;
                align-items: center;
                flex-shrink: 0;
                border-left: 1px solid #f3f3f3;
                padding-left: 20px;
            }
        }
    }

    > .empty {
        width: 100%;
        margin: 40px 0;
        text-align: center;
        color: #888;
    }
}
</style>
